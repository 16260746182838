import { useGetAlgoliaSearchIndex, useSiteStoreCode } from '#imports'
import type { Prescription } from '@/types/prescription'

export const useProductFilterApi = () => {
  const { isEyeBotSiteStore } = useSiteStoreCode()
  const search = (prescription: Prescription, params: any) => {
    const algoliaIndex = useGetAlgoliaSearchIndex()
    return $fetch<any>(`/api/get-product-list`, {
      query: {
        prescription: isEyeBotSiteStore.value ? undefined : prescription,
        algoliaQueryParams: params,
        algoliaIndex,
      },
    })
  }
  return { search }
}
