import { defineStore } from 'pinia'
import { usePrescription, useZenniAlgoliaSearch } from '#imports'
import { useLensInfoApi } from '@/composables/api/useLensInfo'

export const useLensStore = defineStore('lensStore', () => {
  const { prescription } = usePrescription()
  const lensInfoApi = useLensInfoApi()
  const getLensSafetyCode = (frameData: any) => {
    let safetyGroup = ''
    if (frameData.groups.includes('600safetyframes')) {
      safetyGroup = '600'
    } else if (frameData.groups.includes('400safetyframes')) {
      safetyGroup = '400'
    }
    if (safetyGroup) {
      return `groups: ${safetyGroup}safetylens`
    }
    return `NOT groups: 400safetylens AND NOT groups: 600safetylens`
  }

  const getLensInfoFromAlgolia = async (
    prescriptionData = null,
    frameData: any,
    hitsPerPage: number = 100,
    customFilter: string = '',
  ) => {
    const lensSafetyCodeFilterStr = getLensSafetyCode(frameData)
    return await lensInfoApi.search(
      prescriptionData || (prescription?.value as any),
      {
        query: '',
        requestOptions: {
          filters: [lensSafetyCodeFilterStr, customFilter]
            .filter((i) => i)
            .join(' AND '),
          hitsPerPage,
          facets: ['*'],
        },
      },
    )
  }

  const getLensPrescriptionRange = async (
    lensIndex: any,
    prescriptionData: any,
  ) => {
    return await lensInfoApi.getLensPrescriptionRange(
      lensIndex,
      prescriptionData || prescription.value,
    )
  }

  const getFramePrescriptionRange = async () => {
    const { search } = useZenniAlgoliaSearch()
    const res = await search({
      query: '',
      requestOptions: {
        facets: ['*'],
      },
    })
    const framePrescriptionRange = res.facets_stats
    return {
      PD: {
        MAX: framePrescriptionRange?.FrameMaxPD?.max,
      },
      SPH: {
        MIN: framePrescriptionRange?.FrameMinSPH.min,
        MAX: framePrescriptionRange?.FrameMaxSPH.max,
      },
      CYL: {
        MIN: framePrescriptionRange?.FrameMinCYL.min,
        MAX: framePrescriptionRange?.FrameMaxCYL.max,
      },
      JOINT: {
        MIN: framePrescriptionRange?.FrameJointMin.min,
        MAX: framePrescriptionRange?.FrameJointMax.max,
      },
    }
  }

  const getValue = (a: any, b: any, type: string = 'min') => {
    const isNoneValue = (val: any) =>
      val === undefined || val === null || val === '' || isNaN(val)
    if (isNoneValue(a) && isNoneValue(b)) {
      return 0
    } else if (!isNoneValue(a) && isNoneValue(b)) {
      return a
    } else if (isNoneValue(a) && !isNoneValue(b)) {
      return b
    } else {
      return type === 'min'
        ? Math.min(Number(a), Number(b))
        : Math.max(Number(a), Number(b))
    }
  }

  const getPrescriptionRange = async (
    lensIndex: any = '',
    prescriptionData: any = prescription.value,
    framePrescriptionRange: any = null,
  ) => {
    const lensPrescriptionRange = await getLensPrescriptionRange(
      lensIndex,
      prescriptionData,
    )
    if (!framePrescriptionRange) {
      framePrescriptionRange = await getFramePrescriptionRange()
    }
    return {
      PD: {
        MAX: framePrescriptionRange?.PD?.MAX,
      },
      SPH: {
        MIN: -getValue(
          lensPrescriptionRange?.LensNegativeMaxSPH.max,
          Math.abs(Number(framePrescriptionRange?.SPH.MIN)),
          'min',
        ),
        MAX: getValue(
          lensPrescriptionRange?.LensPositiveMaxSPH.max,
          framePrescriptionRange?.SPH.MAX,
          'min',
        ),
      },
      CYL: {
        MIN: -getValue(
          lensPrescriptionRange?.LensMaxCYL.max,
          Math.abs(Number(framePrescriptionRange?.CYL.MIN)),
          'min',
        ),
        MAX: getValue(
          lensPrescriptionRange?.LensMaxCYL.max,
          framePrescriptionRange?.CYL.MAX,
          'min',
        ),
      },
      JOINT: {
        MIN: -getValue(
          Math.abs(Number(lensPrescriptionRange?.LensMinSumCYL.min)),
          Math.abs(Number(framePrescriptionRange?.JOINT.MIN)),
          'min',
        ),
        MAX: getValue(
          lensPrescriptionRange?.LensMaxSumCYL.max,
          framePrescriptionRange?.JOINT.MAX,
          'min',
        ),
      },
      ADD: {
        MIN: 1,
        MAX: lensPrescriptionRange?.LensMaxNVAdd?.max || 3,
      },
    }
  }

  return {
    getLensInfoFromAlgolia,
    getPrescriptionRange,
    getFramePrescriptionRange,
  }
})
