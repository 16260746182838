import insights from 'search-insights'
import { useSiteConfigurationStore } from '../store/siteConfiguration'
import {
  computed,
  ref,
  useCookie,
  onMounted,
  useRuntimeConfig,
  useMagentoCookieState,
} from '#imports'
import { useAlgoliaApi } from '@/composables/api/useAlgolia'
export const getAlgoliaInsights = () => {
  const loggedUser: any = useCookie('user')

  const eventInsights = computed(() => insights)

  onMounted(() => {
    if (loggedUser?.id) insights('setUserToken', 'vsf_user_' + loggedUser?.id)
  })

  return {
    eventInsights,
  }
}

export const useGetAlgoliaSearchIndex = (paramStoreCode?: string) => {
  const config = useRuntimeConfig()
  const siteConfigurationStore = useSiteConfigurationStore()
  const magentoCookieState = useMagentoCookieState()
  const ALGOLIA_SEARCH_INDEX =
    siteConfigurationStore.siteConfiguration.configuration.algoliaSearchIndex
  if (ALGOLIA_SEARCH_INDEX && !paramStoreCode) {
    return ALGOLIA_SEARCH_INDEX
  }
  const storeCode = magentoCookieState.getStore()
  const defaultStoreCode = 'rdp_default_us'
  const resolvedStoreCode = paramStoreCode || storeCode
  const storeCodeValue =
    resolvedStoreCode === 'default' ? defaultStoreCode : resolvedStoreCode

  return 'akeneo_b2b_ecommerce_rdp_$env_$storeCode_products'
    .replace('$env', config.public.environment as string)
    .replace('$storeCode', storeCodeValue || ('rdp_default_us' as string))
}

export const useZenniAlgoliaSearch = (paramStoreCode?: string) => {
  const loading = ref(false)
  const ALGOLIA_SEARCH_INDEX = useGetAlgoliaSearchIndex(paramStoreCode)
  const { search: searchAlgolia } = useAlgoliaApi()
  const result = ref<any>(null)
  const search = async (searchParams: any) => {
    loading.value = true
    const res = await searchAlgolia(ALGOLIA_SEARCH_INDEX, {
      ...searchParams,
    })
    result.value = res
    loading.value = false
    return res
  }

  return {
    search,
    result,
    loading,
    searchGetters: {
      getItems: (res: any) => res?.hits || null,
    },
  }
}
