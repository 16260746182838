import { cloneDeep, isEqual, flattenDeep, isEmpty } from 'lodash-es'
import { usePrescription as usePrescriptionApi } from './api/usePrescription'
import { normalizationPrescription } from '@/utils/normalize/prescriptionNormalize'
import type { Prescription } from '@/types/prescription'
import { useLensStore } from '@/store/lens'
import {
  reactive,
  ref,
  computed,
  onMounted,
  toRaw,
  useSiteStoreCode,
} from '#imports'

interface State {
  prescription: Prescription
}

const state = reactive<State>({
  prescription: {
    pd: 0,
    od: {
      sphere: '0.00',
      cylinders: '0.00',
      axis: '0',
      add: 0,
      prismHor: '0.00',
      prismVer: '0.00',
      pd: 0,
      baseHor: '',
      baseVer: '',
    },
    os: {
      sphere: '0.00',
      cylinders: '0.00',
      axis: '0',
      add: 0,
      prismHor: '0.00',
      prismVer: '0.00',
      pd: 0,
      baseHor: '',
      baseVer: '',
    },
    lensType: 'SingleVision',
    ocrRequestId: '',
  },
})

const emptyPrescription = {
  pd: 0,
  od: {
    sphere: '0.00',
    cylinders: '0.00',
    axis: '0',
    add: 0,
    prismHor: '0.00',
    prismVer: '0.00',
    pd: 0,
    baseHor: '',
    baseVer: '',
  },
  os: {
    sphere: '0.00',
    cylinders: '0.00',
    axis: '0',
    add: 0,
    prismHor: '0.00',
    prismVer: '0.00',
    pd: 0,
    baseHor: '',
    baseVer: '',
  },
  lensType: 'SingleVision',
  ocrRequestId: '',
}

export const usePrescription = () => {
  const errorMessages = ref<string[]>([])
  const warningMessages = ref<string[]>([])
  const { isEyeBotSiteStore } = useSiteStoreCode()
  const validateLensResults = ref<any>(null)
  const prescriptionApi = usePrescriptionApi()
  const { getPrescriptionRange, getFramePrescriptionRange } = useLensStore()
  const setPrescription = (prescriptionNew: Prescription) => {
    if (!prescriptionNew) {
      state.prescription = { ...emptyPrescription }
      localStorage.removeItem('PRESCRIPTION_DATA')
    } else {
      state.prescription = { ...normalizationPrescription(prescriptionNew) }
      localStorage.setItem(
        'PRESCRIPTION_DATA',
        JSON.stringify(state.prescription),
      )
    }
  }

  onMounted(() => {
    const prescriptionData = localStorage.getItem('PRESCRIPTION_DATA')
    if (prescriptionData) {
      state.prescription = JSON.parse(prescriptionData)
    }
  })

  const prescription = computed(() => state.prescription)

  function convertPrescription(prescription: Prescription) {
    if (!prescription) {
      return prescription
    }
    prescription.od.sphere = parseFloat(String(prescription.od.sphere)).toFixed(
      2,
    )
    prescription.od.add = parseFloat(String(prescription.od.add)).toFixed(2)
    prescription.od.cylinders = parseFloat(
      String(prescription.od.cylinders),
    ).toFixed(2)

    prescription.os.sphere = parseFloat(String(prescription.os.sphere)).toFixed(
      2,
    )
    prescription.os.add = parseFloat(String(prescription.os.add)).toFixed(2)
    prescription.os.cylinders = parseFloat(
      String(prescription.os.cylinders),
    ).toFixed(2)

    prescription.od.prismHor = parseFloat(
      String(prescription.od.prismHor),
    ).toFixed(2)
    prescription.od.prismVer = parseFloat(
      String(prescription.od.prismVer),
    ).toFixed(2)

    prescription.os.prismHor = parseFloat(
      String(prescription.os.prismHor),
    ).toFixed(2)
    prescription.os.prismVer = parseFloat(
      String(prescription.os.prismVer),
    ).toFixed(2)
    return prescription
  }

  const getPrescriptionFunction = async () => {
    const { data, error } = await prescriptionApi.get()
    if (error.value) return 'error'
    let results = toRaw(data.value) as any
    // Clear rx type backend return data
    // { pd: 0, od: {}, os: {} }
    // So initialize the default value
    if (isEmpty(results?.od) && isEmpty(results?.os)) {
      results = {
        ...results,
        ...emptyPrescription,
      }
    }
    return convertPrescription(results)
  }

  const loadPrescriptionByUserId = async () => {
    try {
      const result = await getPrescriptionFunction()
      if (result !== 'error') {
        if (result as Prescription) {
          setPrescription(normalizationPrescription(result) as Prescription)
        } else {
          setPrescription(emptyPrescription)
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const isValidPdNUmber = (pd: string) => {
    const data = Number(pd)
    return typeof data === 'number' && !isNaN(data) && data > 0
  }

  const hasValidPd = (prescription: any) => {
    return (
      isValidPdNUmber(prescription?.pd) ||
      (isValidPdNUmber(prescription?.os?.pd) &&
        isValidPdNUmber(prescription?.od?.pd))
    )
  }

  const checkPrescriptionAndPd = async () => {
    if (isEqual(state.prescription, emptyPrescription)) {
      const result = await getPrescriptionFunction()
      if (result !== 'error') {
        const { lensType } = prescription.value
        setPrescription(
          // @ts-ignore
          normalizationPrescription(cloneDeep({ ...result, lensType })),
        )
      }
    } else if (!hasValidPd(state.prescription)) {
      const result = await getPrescriptionFunction()
      if (result !== 'error') {
        const { pd } = result
        setPrescription(
          // @ts-ignore
          normalizationPrescription(cloneDeep({ ...prescription.value, pd })),
        )
      }
    }
  }

  const savePrescription = async () => {
    const prescriptionToSave = cloneDeep(prescription.value)

    if (prescriptionToSave.od.pd && prescriptionToSave.os.pd) {
      prescriptionToSave.pd =
        Number(prescriptionToSave.od.pd) + Number(prescriptionToSave.os.pd)
      prescriptionToSave.od.pd = Number(prescriptionToSave.od.pd)
      prescriptionToSave.os.pd = Number(prescriptionToSave.os.pd)
    } else {
      prescriptionToSave.pd = Number(prescriptionToSave.pd)
    }

    prescriptionToSave.od.sphere = Number(prescriptionToSave.od.sphere)
    prescriptionToSave.od.cylinders = Number(prescriptionToSave.od.cylinders)
    prescriptionToSave.od.axis = Number(prescriptionToSave.od.axis)
    prescriptionToSave.od.add = Number(prescriptionToSave.od.add)

    prescriptionToSave.od.prismHor = Number(prescriptionToSave.od.prismHor)
    prescriptionToSave.od.baseHor = prescriptionToSave.od.baseHor || ''
    prescriptionToSave.od.prismVer = Number(prescriptionToSave.od.prismVer)
    prescriptionToSave.od.baseVer = prescriptionToSave.od.baseVer || ''
    prescriptionToSave.os.sphere = Number(prescriptionToSave.os.sphere)
    prescriptionToSave.os.cylinders = Number(prescriptionToSave.os.cylinders)
    prescriptionToSave.os.axis = Number(prescriptionToSave.os.axis)
    prescriptionToSave.os.add = Number(prescriptionToSave.os.add)

    prescriptionToSave.os.prismHor = Number(prescriptionToSave.os.prismHor)
    prescriptionToSave.os.baseHor = prescriptionToSave.os.baseHor || ''
    prescriptionToSave.os.prismVer = Number(prescriptionToSave.os.prismVer)
    prescriptionToSave.os.baseVer = prescriptionToSave.os.baseVer || ''
    prescriptionToSave.lensType = prescriptionToSave.lensType || 'SingleVision'
    prescriptionToSave.lensCode = prescriptionToSave.lensCode || ''

    const { data, error } = await prescriptionApi.save({
      ...prescriptionToSave,
    })
    if (error.value) {
      // eslint-disable-next-line no-console
      console.error(error)
      return
    }

    const results = toRaw(data.value) as any
    return results
  }

  const resetPrism = () => {
    setPrescription({
      ...prescription.value,
      od: {
        ...prescription.value.od,
        prismHor: '0.00',
        prismVer: '0.00',
        baseHor: '',
        baseVer: '',
      },
      os: {
        ...prescription.value.os,
        prismHor: '0.00',
        prismVer: '0.00',
        baseHor: '',
        baseVer: '',
      },
    })
  }

  const validatePrescriptionLens = async (
    lensIndex: string = '',
    cartItemPrescription: any = null,
  ) => {
    if (
      !cartItemPrescription &&
      !isEyeBotSiteStore.value &&
      isEqual(state.prescription, emptyPrescription)
    ) {
      await loadPrescriptionByUserId()
    }
    errorMessages.value = []
    warningMessages.value = []
    validateLensResults.value = null
    const framePrescriptionRange = await getFramePrescriptionRange()
    const prescriptionRange = await getPrescriptionRange(
      lensIndex,
      cartItemPrescription,
      framePrescriptionRange,
    )
    const { data } = await prescriptionApi.validatePrescriptionAndLens(
      cartItemPrescription || state.prescription,
      prescriptionRange,
      framePrescriptionRange,
    )

    const results = toRaw(data.value) as any

    const prescriptionValidateResults = results.validatePrescriptionResults
    const lensTypeValidationResults = results.validateLensResults

    if (
      prescriptionValidateResults.success &&
      lensTypeValidationResults.hasLens
    ) {
      validateLensResults.value = lensTypeValidationResults.itemTypes
    } else if (!prescriptionValidateResults.success) {
      errorMessages.value = flattenDeep(
        Object.keys(prescriptionValidateResults.errorMessages).map((key) => {
          return prescriptionValidateResults.errorMessages[key]
        }),
      )
    } else if (!lensTypeValidationResults.hasLens) {
      errorMessages.value = [lensTypeValidationResults.errorMessage]
    }
  }

  const clearRxTypeToDb = async (prescriptionParams: any) => {
    const { data, error } = await prescriptionApi.update(prescriptionParams)

    if (error.value) {
      // eslint-disable-next-line no-console
      console.error(error)
      return
    }

    const results = toRaw(data.value) as any
    setPrescription({
      ...results,
      ...emptyPrescription,
    })
    return results
  }

  return {
    prescription,
    getPrescriptionFunction,
    loadPrescriptionByUserId,
    setPrescription,
    savePrescription,
    checkPrescriptionAndPd,
    resetPrism,
    validatePrescriptionLens,
    validateLensResults,
    warningMessages,
    errorMessages,
    emptyPrescription,
    clearRxTypeToDb,
  }
}
