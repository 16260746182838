import { useZenniFetch } from '@/composables/useZenniFetch'

export const useVTO = () => {
  const get = async () => {
    return await useZenniFetch('/platform/api/vto')
  }

  const save = async (params: any) => {
    return await useZenniFetch('/platform/api/vto', {
      method: 'POST',
      body: params,
    })
  }

  return {
    get,
    save,
  }
}
