import type { Prescription } from '@/types/prescription'

const formattedRxType = (lensType: string, progressiveType?: string) => {
  if (!lensType) return ''

  if (lensType === 'Non Prescription') return 'NonRx'

  if (lensType === 'Progressive') {
    lensType = `${progressiveType} ${lensType}`
  }

  const parts = lensType?.split('-').join(' ')
  const firstLetter = parts.charAt(0).toLocaleUpperCase()
  const restOfValue = parts
    .slice(1)
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
  return `${firstLetter}${restOfValue}`
}

const formatNumberToCustomString = (
  value: string | number,
  prefix: boolean = true,
) => {
  if (value == null || value === '') return value

  if (Number(value) > 0) {
    return `${prefix ? '+' : ''}${Number(value).toFixed(2)}`
  }

  return Number(value).toFixed(2)
}

const normalizationPd = (pdData: string | number) => {
  return Number(pdData)
}

const formatNumberToString = (value?: string | number) => {
  if (!value) return '0.00'

  return ['0', 0].includes(value) ? '0.00' : value
}

const formatNumberToStringWithoutDecimal = (value?: string | number) => {
  if (!value) return '0'

  return value === 0 ? '0' : value
}

const formatString = (value?: string | number) => {
  if (value === null || value === undefined || value === 0) {
    return ''
  }
  if (typeof value !== 'string') {
    return `${value}`
  }
  return value
}

const normalizationDetail = (data: Prescription['od']) => {
  return {
    ...data,
    sphere: formatNumberToString(data.sphere),
    cylinders: formatNumberToString(data.cylinders),
    axis: formatNumberToStringWithoutDecimal(data.axis),
    prismHor: formatNumberToString(data.prismHor),
    prismVer: formatNumberToString(data.prismVer),
    baseHor: formatString(data.baseHor),
    baseVer: formatString(data.baseVer),
  }
}

const normalizationPrescription = (prescriptionData: Prescription) => {
  const { pd, od, os } = prescriptionData
  return {
    ...prescriptionData,
    pd: normalizationPd(pd),
    od: normalizationDetail(od),
    os: normalizationDetail(os),
  }
}

export {
  formattedRxType,
  formatNumberToCustomString,
  formatNumberToString,
  normalizationPrescription,
  normalizationDetail,
}
