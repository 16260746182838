import { defineStore } from 'pinia'
import _ from 'lodash'
import { useGetAlgoliaSearchIndex } from '../composables/useAlgolia'
import { useLensStore } from './lens'
import { useSiteConfigurationStore } from './siteConfiguration'
import { useCart } from '@/composables/useCart'
import { computed, getAlgoliaInsights, ref, useSiteStoreCode } from '#imports'
import { EYE_BOT_PRESCRIPTION_ID } from '@/utils/constants/eyeBot'

export const useLensUiStore = defineStore('lensUiStore', () => {
  const { addItem, error: cartErrors, updateItem } = useCart()
  const lensStore = useLensStore()
  const tintCode = ref('')
  const tintSku = ref('')
  const { eventInsights } = getAlgoliaInsights()
  const siteConfigurationStore = useSiteConfigurationStore()
  const lensFacets = ref<any>(null)
  const subLensFacets = ref<any>(null)
  const lensIndexList = ref<any>([])
  const { isEyeBotSiteStore } = useSiteStoreCode()
  const prism = computed(
    () => siteConfigurationStore.siteConfiguration.configuration.prismSku,
  )

  const enableChooseCoating = computed(
    () =>
      siteConfigurationStore.siteConfiguration.configuration
        .enableChooseCoating,
  )

  const activeLensTypeMenu = ref('')
  const activeLensSubTypeMenu = ref('')

  const getLensTypeFacets = async (prescription = null, frameData: any) => {
    const res = await lensStore.getLensInfoFromAlgolia(prescription, frameData)
    lensFacets.value = res.facets
  }

  const getSubLensTypeFacets = async (prescription = null, frameData: any) => {
    const res = await lensStore.getLensInfoFromAlgolia(
      prescription,
      frameData,
      0,
      lensTypeList.value.find(({ value }) => value === activeLensTypeMenu.value)
        ?.filter,
    )
    subLensFacets.value = res.facets
  }

  const getLensIndexList = async (prescription = null, frameData: any) => {
    const selectItem = lensTypeList.value.find(
      ({ value }: any) => value === activeLensTypeMenu.value,
    )
    const subSelectItem = selectItem.subMenu.find(
      ({ value }: any) => value === activeLensSubTypeMenu.value,
    )

    const res = await lensStore.getLensInfoFromAlgolia(
      prescription,
      frameData,
      100,
      [selectItem.filter, subSelectItem.filter]
        .filter((i) => !!i)
        .join(' AND '),
    )
    lensIndexList.value = res.hits
  }

  // check filter if meet the condition by facets
  const isExist = (facets: any, filter: string) => {
    const condition: { [key: string]: string } = _.fromPairs(
      filter.split(' AND ').map((pair: any) => pair.split(': ')),
    )
    let isExit = true
    Object.keys(condition).forEach((key) => {
      if (!(facets?.[key]?.[condition[key]] ?? false)) {
        isExit = false
      }
    })
    return isExit
  }

  const lensTypeList = computed<any[]>(() => {
    if (!lensFacets.value) {
      return [] as any[]
    }
    return (
      siteConfigurationStore.siteConfiguration?.lensOptions
        ?.lensTypeListModel ?? []
    )?.filter((lensTypeListItem: any) => {
      return isExist(lensFacets.value, lensTypeListItem.filter)
    })
  })

  const lensIndex = ref<any>(null)
  const lensCoating = ref<any>(null)

  const coatings = computed(() => {
    const coatingArr = siteConfigurationStore.siteConfiguration.configuration
      .coatingSku as any[]
    if (coatingArr.length === 1) {
      return coatingArr
    }
    const lensIndexCoating = lensIndex.value?.associations?.Available_Coatings
    const availableCoating = _.intersection(coatingArr, lensIndexCoating)
    return enableChooseCoating.value ? availableCoating : [availableCoating[0]]
  })

  const hasCoating = computed(() => coatings.value?.length > 1)

  const hasPhotochromicTint = computed(
    () =>
      activeLensTypeMenu.value === 'photochromic' &&
      lensIndex.value?.Lens___Color &&
      lensIndex.value?.Lens___Color !== 'clear',
  )

  const hasStandardTint = computed(
    () =>
      !_.isEmpty(lensIndex.value?.associations.Available_Tints) &&
      activeLensSubTypeMenu.value !== 'sunglassesPolarizedNoTint',
  )

  const hasTint = computed(() => hasPhotochromicTint.value || hasStandardTint)

  const setLensCoating = (data: string) => {
    lensCoating.value = data
  }

  const setLensIndex = (data: any) => {
    lensIndex.value = data
    setTintCode('')
    setTintSku('')
    setLensCoating('')
  }

  const setActiveLensTypeMenu = (data: string) => {
    activeLensTypeMenu.value = data
    setActiveLensSubTypeMenu('')
  }

  const setActiveLensSubTypeMenu = (data: string) => {
    activeLensSubTypeMenu.value = data
    setLensIndex(null)
  }

  const setTintCode = (data: string) => {
    tintCode.value = data
  }

  const setTintSku = (data: string) => {
    tintSku.value = data
  }

  const lensSubTypeList = computed(() => {
    if (!activeLensTypeMenu.value || !subLensFacets.value) {
      return [] as any[]
    }
    const copyData = _.cloneDeep(subLensFacets.value)
    const blokzNumber = copyData?.Blue_light_options?.blokz
    const sunglassesClassicNumber = copyData?.Sunglasses_Type?.classic
    if (blokzNumber && sunglassesClassicNumber) {
      // cause sunglassesBlokz lens also return Sunglasses_Type: classic, repeat with sunglassesClassic lens, so remove
      copyData.Sunglasses_Type.classic -= blokzNumber
    }

    const selectItem = lensTypeList.value.find(
      ({ value }: any) => value === activeLensTypeMenu.value,
    )
    return selectItem.subMenu.filter((subMenuItem: any) => {
      const filter = subMenuItem.filter
      return isExist(copyData, filter)
    })
  })

  const hasPrism = (prescription: any) => {
    return (
      Number(prescription.od.prismHor) > 0 ||
      Number(prescription.od.prismVer) > 0 ||
      Number(prescription.os.prismVer) > 0 ||
      Number(prescription.os.prismVer) > 0
    )
  }

  const clear = () => {
    setLensIndex(null)
    setActiveLensSubTypeMenu('')
    setActiveLensTypeMenu('')
    setTintCode('')
    setTintSku('')
    setLensCoating('')
  }
  const translateToNumber = (data: any) => {
    const res: any = {}
    const field = [
      'add',
      'sphere',
      'cylinders',
      'axis',
      'prismHor',
      'prismVer',
      'add',
      'pd',
    ]
    Object.keys(data).forEach((key) => {
      res[key] = field.includes(key) ? Number(data[key]) : data[key]
    })
    return res
  }

  const getCartPayload = (frameSku: string, prescription?: any) => {
    const items = [{ sku: frameSku }]
    if (lensIndex.value) {
      items.push({ sku: lensIndex.value?.sku })
      items.push({ sku: lensCoating.value || coatings.value[0] })
    }

    if (tintSku.value) {
      items.push({ sku: tintSku.value })
    }

    if (
      !isEyeBotSiteStore.value &&
      lensIndex.value &&
      lensIndex.value.Lens___Prism_compatibility === 'standard' &&
      hasPrism(prescription)
    ) {
      items.push({ sku: prism.value as string })
    }

    const lensTypeMap: { [key: string]: any } = {
      NonRx: 'nonprescription',
      PremiumProgressive: 'progressive',
      StandardProgressive: 'progressive',
      Bifocals: 'bifocal',
    }

    const progressiveTypeMap: { [key: string]: any } = {
      PremiumProgressive: 'premium',
      StandardProgressive: 'standard',
    }

    return {
      items,
      isPrescription:
        isEyeBotSiteStore.value ||
        (!!prescription && prescription.lensType !== 'NonRx'),
      prescriptionLater: isEyeBotSiteStore.value,
      prescription:
        (!isEyeBotSiteStore.value &&
          prescription && {
            isOlderThan12: true,
            pd: prescription.pd,
            lensType: (
              lensTypeMap[prescription.lensType] || prescription.lensType
            ).toLowerCase(),
            od: translateToNumber(prescription.od),
            os: translateToNumber(prescription.os),
            ocrRequestId: prescription.ocrRequestId,
            tint: tintCode.value,
            progressiveType: progressiveTypeMap[prescription.lensType],
          }) ||
        undefined,
    }
  }

  const addToCart = async (frameSku: string, prescription?: any) => {
    await addItem(getCartPayload(frameSku, prescription))
    if (!cartErrors.value.addItem) {
      /* send events to algolia */
      clear()
      const algoliaIndex = useGetAlgoliaSearchIndex()
      eventInsights.value('convertedObjectIDs', {
        index: algoliaIndex,
        eventName: 'PDP: Product Added to Cart',
        objectIDs: [frameSku],
      })
    }
  }

  const updateCart = async (
    bundleId: string,
    frameSku: string,
    prescription: any,
  ) => {
    const payload = getCartPayload(frameSku, prescription)
    await updateItem({
      bundleId,
      newItems: payload,
    })
    clear()
  }

  const addEyeBotPrescriptionToCart = async () => {
    await addItem({
      items: [{ sku: EYE_BOT_PRESCRIPTION_ID }],
      isPrescription: false,
      prescriptionLater: true,
    })
  }

  return {
    addEyeBotPrescriptionToCart,
    clear,
    addToCart,
    updateCart,
    activeLensTypeMenu,
    activeLensSubTypeMenu,
    getLensTypeFacets,
    getLensIndexList,
    setActiveLensTypeMenu,
    setActiveLensSubTypeMenu,
    lensTypeList,
    lensSubTypeList,
    lensIndexList,
    lensIndex,
    setLensIndex,
    setTintCode,
    setTintSku,
    lensCoating,
    setLensCoating,
    tintSku,
    tintCode,
    getSubLensTypeFacets,
    coatings,
    hasCoating,
    hasPhotochromicTint,
    hasStandardTint,
    hasTint,
    prism,
    hasPrism,
  }
})
