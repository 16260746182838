export const useAlgoliaApi = () => {
  const search = (algoliaIndex: string, queryParams: any) => {
    return $fetch<any>(`/api/algoliaSearch`, {
      query: {
        queryParams,
        algoliaIndex,
      },
    })
  }
  return { search }
}
