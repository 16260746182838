import { COLOR_MAPPINGS } from './constants'

interface Filters {
  frameShapes: string[]
  sizes: string[]
  category: string[]
  colors: string[]
  frameType: string[]
}

const createFacetFilter = (filterName: string, filters: string[]) => {
  if (filters.length === 0) {
    return undefined
  }

  if (filters.length === 1) {
    return [`${filterName}:${filters[0]}`]
  }

  return [filters.map((filter) => `${filterName}:${filter}`)]
}

const createSizeFilter = (filters: string[]) => {
  if (filters.length === 0) {
    return undefined
  }

  const createSizeFilterQuery = (filter: string) => {
    return `Frame___Size:"${filter}"`
  }

  if (filters.length === 1) {
    return createSizeFilterQuery(filters[0])
  }

  return `(${filters
    .map((filter) => createSizeFilterQuery(filter))
    .join(' OR ')})`
}

const createGenderFilter = (filter: string[]) => {
  if (!filter || filter.length === 0) {
    return undefined
  }
  const formattedFilter =
    filter.length > 1
      ? filter.map((item) => `Frame___Gender: ${item} OR `).join('')
      : `Frame___Gender:${filter.at(-1)} OR `
  return `(${formattedFilter}Frame___Gender:Unisex OR Frame___Gender:Unisex_Kids)`
}

const createFrameTypeFilter = (filter: string[]) => {
  if (!filter || filter.length === 0) {
    return undefined
  }
  return `frame_type_code:${filter}`
}
const createColorFilter = (filters: string[]) => {
  if (filters.length === 0) {
    return undefined
  }

  const createColorFilterQuery = (filter: string) => {
    const colors: string[] = COLOR_MAPPINGS[filter] || []
    return `${colors
      .filter((cItem) => !!cItem)
      .map((color) => `Main_Color:"${color}"`)
      .join(' OR ')}`
  }

  return `(${filters
    .map((filter) => createColorFilterQuery(filter))
    .filter((fItem) => !!fItem)
    .join(' OR ')})`
}

export const getFilterQueryArray = (filters: Filters) => {
  return [
    createSizeFilter(filters.sizes),
    createFrameTypeFilter(filters.frameType),
    createGenderFilter(filters.category),
    createColorFilter(filters.colors),
  ].filter((item) => !!item)
}

export const getFacetFilterArray = (filters: Filters) => {
  return [createFacetFilter('Frame___Shape', filters.frameShapes)].filter(
    (item) => !!item,
  )
}

const createFilterQuery = (filters: Filters) => {
  const filterQueryArray = getFilterQueryArray(filters).filter(
    (item) => item && item.trim() !== '',
  )

  const filterStr = filterQueryArray.join(' AND ')

  return [filterStr].filter((item) => item && item.trim() !== '').join(' AND ')
}

export const getRequestOptions = (filters: Filters) => {
  return {
    facetFilters: createFacetFilter('Frame___Shape', filters.frameShapes),
    filters: createFilterQuery(filters),
  }
}
