import { useNuxtApp, useRuntimeConfig } from 'nuxt/app'
import { useVTO as useVTOApi } from '@/composables/api/useVTO'
import {
  reactive,
  computed,
  ref,
  onMounted,
  onUnmounted,
  toRaw,
} from '#imports'

const state = reactive<any>({ vto: null })

export const useVTO = () => {
  const { $vtoContext } = useNuxtApp()
  const vtoApi = useVTOApi()
  const config = useRuntimeConfig()

  const overlay = ref<any>(null)
  const image = ref<null | string>(null)

  const setVTO = (newVTO: { [key: string]: any }) => {
    if (newVTO) {
      state.vto = { ...newVTO }
      localStorage.setItem('VTO_DATA', JSON.stringify(state.vto))
    } else {
      state.vto = null
      localStorage.removeItem('VTO_DATA')
    }
  }

  onMounted(() => {
    const vtoData = localStorage.getItem('VTO_DATA')
    if (vtoData) {
      state.vto = JSON.parse(vtoData)
    }
  })

  const vto = computed(() => state.vto)

  const getFrameSize = (faceWidth: number) => {
    const frameWidth = Math.round(faceWidth)
    if (frameWidth > 140) return 'ExtraLarge'
    if (frameWidth >= 133) return 'Large'
    if (frameWidth >= 126) return 'Medium'
    if (frameWidth >= 119) return 'Small'
    return 'ExtraSmall'
  }

  const setVtoFromDatabase = async () => {
    if (vto?.value) return
    const vtoDb = await getVtoFromDatabase()
    if (vtoDb) {
      setVTO({
        ...vto.value,
        ...vtoDb,
      })
    }
  }

  const getVtoFromDatabase = async () => {
    const { data, error } = await vtoApi.get()
    if (error.value) return null
    const results = toRaw(data.value) as any
    if (results && results.scanId) {
      results.overlaySignature = $vtoContext.vtoGetOverlaySignature(
        results.scanId,
      )
    }
    return results
  }

  const saveVtoIntoDatabase = async () => {
    const VTOUpsert = {
      ...vto.value,
    }
    delete VTOUpsert.status
    delete VTOUpsert.realScale
    delete VTOUpsert.userId

    const { data, error } = await vtoApi.save({
      VTOUpsert,
    })
    if (error.value) {
      // eslint-disable-next-line no-console
      console.error(error)
      return
    }
    const results = toRaw(data.value) as any

    return results
  }

  const getFrontalFrame = ({ glassesId }: any) => {
    if (state.vto.scanId) {
      return $vtoContext.vtoGetFrontal(
        {
          scanId: state.vto.scanId,
          glassesId,
          overlaySignature: state.vto.overlaySignature,
        },
        (data: any) => {
          image.value = URL.createObjectURL(data)
        },
        (error: Error) => {
          // eslint-disable-next-line no-console
          console.error(error)
          image.value = null
        },
      )
    }
  }

  const startOverlay = ({ glassesId, domSelector }: any) => {
    overlay.value = $vtoContext.vtoOverlay({
      scanId: state.vto.scanId,
      domSelector,
      glassesId,
      overlaySignature: state.vto.overlaySignature,
    })
  }

  const initGlasseson = (
    resultCallback: any,
    pdConfig?: { fontFamily?: string; themeColor?: string; width?: number },
  ) => {
    try {
      const clientId = config?.public?.glassesonClientId
      const serverUrl = config?.public?.glassesonApiUrl
      const options = {
        profileName: 'zennibb_web',
        flow: 'pd',
        fullPage: false,
        themeColor: '#bad80a',
        fontFamily: 'Arial',
        ...(pdConfig || {}),
      }

      if (window.glasseson.initCompleted) {
        window.glasseson.open('pd')
        window.glasseson.setResultCallback(resultCallback)
        return
      }

      window.glasseson.init(clientId, serverUrl, options).then(() => {
        window.glasseson.open('pd')
        window.glasseson.setResultCallback(resultCallback)
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw error
    }
  }

  onUnmounted(() => {
    if (overlay.value) {
      overlay.value?.destroy()
      overlay.value = null
    }
  })

  return {
    getFrameSize,
    getVtoFromDatabase,
    setVtoFromDatabase,
    saveVtoIntoDatabase,
    setVTO,
    getFrontalFrame,
    startOverlay,
    vto,
    overlay,
    image,
    initGlasseson,
  }
}
