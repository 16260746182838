import { usePrescription } from '@/composables/usePrescription'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import _ from 'lodash'
import { useProductFilterApi } from '@/composables/api/useProductFilter'
import { useVTO } from '../useVTO'

import { COLOR_IMAGES, SHAPES_IMAGES, LENS_TYPES } from './constants'
import { getFilterQueryArray, getRequestOptions } from './utils'
import { reactive, computed, onMounted, ref, watch } from '#imports'

const state = reactive<{ filters: { [key: string]: any } }>({
  filters: {
    frameShapes: [],
    sizes: [],
    category: [],
    colors: [],
    lensType: '',
    frameType: [],
  },
})

interface FiltersInputProps {
  category: string
  value: any
  isReplace?: boolean
}

export const useProductFilter = () => {
  const lensTypes = ref(LENS_TYPES)
  const { prescription, setPrescription } = usePrescription()
  const { vto } = useVTO()
  const siteConfigurationStore = useSiteConfigurationStore()

  const sizes = ref(siteConfigurationStore.siteConfiguration.filters?.sizes)
  const frameShapes = ref(
    siteConfigurationStore.siteConfiguration.filters?.shapes,
  )
  const categories = ref(
    siteConfigurationStore.siteConfiguration.filters?.categories,
  )
  const colors = ref(siteConfigurationStore.siteConfiguration.filters?.colors)
  const productList = ref<{ hits: any[]; nbPages: number }>({
    hits: [],
    nbPages: 0,
  })
  const recommendedSize = computed(
    () =>
      sizes.value.find((size) => size.value === vto.value?.recommandSize)
        ?.label,
  )
  const recommendedTypes = computed(() => vto.value?.recommandType?.join(' & '))
  const faceShape = computed(() => vto.value?.faceShape)
  const productFilterApi = useProductFilterApi()

  const updateArrayFilters = ({
    category,
    value,
    isReplace = false,
  }: FiltersInputProps) => {
    if (isReplace) {
      return (state.filters = {
        ...filters.value,
        [category]: value,
      })
    }

    if (filters.value[category].includes(value)) {
      const newFilters = {
        ...filters.value,
        [category]: filters.value[category].filter(
          (filter: string) => filter !== value,
        ),
      }
      state.filters = { ...newFilters }
    } else {
      const newFilters = {
        ...filters.value,
        [category]: [...filters.value[category], value],
      }
      state.filters = { ...newFilters }
    }
  }

  // isReplace: Replace data directly
  const setFilters = ({
    category,
    value,
    isReplace = false,
  }: FiltersInputProps) => {
    if (
      category === 'sizes' ||
      category === 'colors' ||
      category === 'frameShapes' ||
      category === 'category' ||
      category === 'frameType'
    ) {
      updateArrayFilters({ category, value, isReplace })
    } else {
      const newFilters = {
        ...filters.value,
        [category]: value,
      }
      state.filters = { ...newFilters }
    }
    if (category === 'lensType' && value !== prescription.value.lensType) {
      setPrescription({
        ...prescription.value,
        lensType: value || 'SingleVision',
      })
    }
    sessionStorage.setItem('FILTERS', JSON.stringify(state.filters))
  }

  const filters = computed(() => state.filters)

  const hasFilterValue = computed(
    () =>
      state.filters.colors.length ||
      state.filters.frameShapes.length ||
      state.filters.sizes.length ||
      state.filters.frameType.length ||
      state.filters.category.length,
  )

  const setRecommendationSize = () => {
    const translate = (data: string) => {
      return data.replace('Extra', 'Extra ')
    }
    if (
      vto.value?.recommandSize &&
      !state.filters.sizes?.includes(translate(vto.value?.recommandSize))
    ) {
      setFilters({
        category: 'sizes',
        value: translate(vto.value?.recommandSize),
      })
    }
  }

  watch(prescription, () => {
    if (prescription.value.lensType && !state.filters.lensType) {
      setFilters({ category: 'lensType', value: prescription.value.lensType })
    }
  })

  const clearFilters = () => {
    state.filters = {
      frameShapes: [],
      sizes: [],
      category: [],
      colors: [],
      frameType: [],
    }
    sessionStorage.setItem('FILTERS', JSON.stringify(state.filters))
  }

  const getRecommendedFilters = () => {
    frameShapes.value = frameShapes.value.map((shape) => {
      if (vto.value?.recommandType?.includes(shape.value)) {
        return {
          ...shape,
          recommended: true,
        }
      }
      return shape
    })

    sizes.value = sizes.value.map((size) => {
      if (vto.value?.recommandSize === size.value) {
        return {
          ...size,
          recommended: true,
        }
      }
      return size
    })
  }

  onMounted(() => {
    const filtersData = sessionStorage.getItem('FILTERS')
    if (filtersData) {
      state.filters = JSON.parse(filtersData)
    }
    getRecommendedFilters()
  })

  const getAlgoliaRequestOptions = () => {
    return getRequestOptions(filters.value as any)
  }

  const searchProductList = async (data: any) => {
    const options = getAlgoliaRequestOptions()
    const _data = _.cloneDeep(data)
    _data.requestOptions = { ..._data.requestOptions, ...options }
    const res = await productFilterApi.search(prescription.value, data)
    productList.value = res ?? { hits: [] }
  }

  return {
    filters,
    recommendedSize,
    recommendedTypes,
    sizes,
    frameShapes,
    categories,
    colors,
    lensTypes,
    faceShape,
    hasFilterValue,
    setRecommendationSize,
    setFilters,
    clearFilters,
    colorImages: COLOR_IMAGES,
    shapeImages: SHAPES_IMAGES,
    getAlgoliaRequestOptions,
    getFilterQueryArray,
    searchProductList,
    productList,
  }
}
